import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root', // Ensures it's available throughout the app// Provide DatePipe explicitly
})
export class DateUtilService {
  constructor(private datePipe: DatePipe) {}

  formatDate(date: any, format: string = 'yyyy-MM-dd'): string | null {
    if (!date) return null;

    // Try parsing the date
    const parsedDate = new Date(date);
    
    // Check if the parsed date is valid
    if (isNaN(parsedDate.getTime())) return null;

    return this.datePipe.transform(parsedDate, format);
  }
}
