import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-all-report',
  templateUrl: './all-report.component.html',
  styleUrls: ['./all-report.component.scss']
})
export class AllReportComponent implements OnInit {
  filterData: any={
    pageNo:1,
    size:10,
    assignMember:'',
    from:'',
    to:'',
    color:'',
    status:'',
    clientId:''
}
p:any=1;
appList:any=[];
total:any=0;
loginType:any;
loginId:any;
teamName:any=''
userList:any=[];

  constructor(private webapiservice:WebapiService) { }

  ngOnInit(): void {
    this.loginType = sessionStorage.getItem('type');
    this.loginId = sessionStorage.getItem('loginId');
    this.teamName = sessionStorage.getItem('teamName');
    if(this.loginType == 3){
      this.filterData.clientId = this.loginId;
      this.getUserByType(6);
    }
  }

  getUserByType(id:any){
    this.webapiservice.getUserByType(id).subscribe((res:any)=>{
      // console.log(res);
      this.userList = res.data
    });
  }

  onFilterData(data:any){
    this.webapiservice.getFullReportApp(data).subscribe((res:any)=>{
      // console.log(res);
      if(res.total > 0){
        this.appList = res.data;
        this.total = res.total;
      }
      else{
        this.appList = []
        this.total = 0;
      }
    });
  }

  formatDateV2(dateString:any){
    const date = new Date(dateString);
  
    // Convert to IST (UTC + 5:30)
    const istOffset = 5.5 * 60 * 60 * 1000; // Offset in milliseconds
    const istDate = new Date(date.getTime() - istOffset);
  
    // Format the date parts
    const day = String(istDate.getDate()).padStart(2, '0');
    const month = String(istDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = istDate.getFullYear();
    const hours = String(istDate.getHours()).padStart(2, '0');
    const minutes = String(istDate.getMinutes()).padStart(2, '0');
  
    // Return formatted string
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  }
  
  onTableDataChange(event: any) {
    this.filterData.pageNo = event;
    this.onFilterData(this.filterData)
    this.p = event;
}

getExcelDownload(data:any){
  this.webapiservice.ExportFullReportApp(data).subscribe((res:any)=>{
    //  console.log(res);
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
         FileSaver.saveAs(blob, 'FullReport.xlsx');
  });
}

}
