import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class WebapiService {

  // private url = 'http://localhost:3000/';
  // public imageUrl = 'http://localhost:3000/public/images/';
  // public reportUrl = 'http://localhost:3000/public/gen-report/';
  // public inviteUrl = "http://localhost:4200/"

  // private url = 'https://foxoglobal.com:3000/';
  // public imageUrl = 'https://foxoglobal.com:3000/public/images/';
  // public reportUrl = 'https://foxoglobal.com:3000/public/gen-report/';
  // public inviteUrl = "https://foxoglobal.com/"
  //
  private url = 'http://bgverp.foxivision.com:3000/';
  public imageUrl = 'http://bgverp.foxivision.com:3000/public/images/';
  public reportUrl = 'http://bgverp.foxivision.com:3000/public/gen-report/';
  public inviteUrl = "http://bgvapp.foxivision.com/"

  constructor(private http: HttpClient) { }

  isLogedIn(){
    return sessionStorage.getItem('loginId');
  }

  insertUser(data:any){
    return this.http.post(this.url+"api/v1/createUpdateUser", data);
  }
  insertInsufLog(data:any){
    return this.http.post(this.url+"api/v1/createUpdateInsufLog", data);
  }
  insertTaskLog(data:any){
    return this.http.post(this.url+"api/v1/createUpdateTaskLog", data);
  }
  exportFile(data:any){
    return this.http.post(this.url+"api/v1/export",data,{ responseType: 'arraybuffer' });
  }
  exportInsufData(data:any){
    return this.http.post(this.url+"api/v1/exportInsufData",data,{ responseType: 'arraybuffer' });
  }
  exportInsufClearedData(data:any){
    return this.http.post(this.url+"api/v1/exportInsufClearedData",data,{ responseType: 'arraybuffer' });
  }

  exportMisReport(data:any){
    return this.http.post(this.url+"api/v1/exportClientMisData",data,{ responseType: 'arraybuffer' });
  }
  exportBillData(data:any){
    return this.http.post(this.url+"api/v1/exportBillData",data,{ responseType: 'arraybuffer' });
  }
  getAllUser(id=""){
    return this.http.get(this.url+"api/v1/getAllUser"+id);
   }
   getUserById(id:any){
    // let cacheBustingValue = new Date().getTime();
    return this.http.get(this.url+"api/v1/getUser/"+id);
   }
   getUserByType(id:any){
    // let cacheBustingValue = new Date().getTime();
    return this.http.get(this.url+"api/v1/getUserByType/"+id);
   }
   deleteUser(data){
    return this.http.post(this.url+"api/v1/deleteUser/",data);
   }
  insertTeam(data:any){
    return this.http.post(this.url+"api/v1/createUpdateTeam", data);
  }
  getFullReportApp(data:any){
    return this.http.post(this.url+"api/v1/getFullReportApp", data);
  }
  ExportFullReportApp(data:any){
    return this.http.post(this.url+"api/v1/ExportFullReportApp", data,{ responseType: 'arraybuffer' });
  }
  getAllTeam(id=""){
    return this.http.get(this.url+"api/v1/getAllTeam"+id);
   }
   getTeamById(id){
    return this.http.get(this.url+"api/v1/getTeam/"+id);
   }
   deleteTeam(data:any){
    return this.http.post(this.url+"api/v1/deleteTeam",data);
   }
   insertCheck(data:any){
    return this.http.post(this.url+"api/v1/setting", data);
  }
  getAllCheck(id=""){
    return this.http.get(this.url+"api/v1/setting"+id);
   }
   getAllManager(id=""){
    return this.http.get(this.url+"api/v1/getAllManager"+id);
   }

   uploadImage(data:any){
    return this.http.post(this.url+"api/v1/uploadImage",data);
   }
   uploadMedia(data:any){
    return this.http.post(this.url+"api/v1/uploadMedia",data);
   }
   uploadZipS3(data:any){
    return this.http.post(this.url+"api/v1/uploadZipS3",data);
   }
   uploadMultipleImage(data:any){
    return this.http.post(this.url+"api/v1/uploadMultipleImage",data);
   }
   getAppCount(id){
    return this.http.get(this.url+"api/v1/getAppCount/"+id);
   }

   doLogin(data:any){
    return this.http.post(this.url+"api/v1/doLogin",data);
   }
   ///////////////////for application page
   getApplicationdetail(data:any){
    return this.http.post(this.url+"api/v1/getApplicationdetail",data);
   }
   getAllClient(id=''){
    return this.http.get(this.url+"api/v1/getAllClient");
   }
    getProcessChecksByClient(data:any){
    return this.http.post(this.url+"getProcessChecksByClient",data);
   }
    getProcessChecks(data:any){
    return this.http.post(this.url+"api/v1/getApplicationdetail",data);
   }
    getBothColumnByTable(data:any){
    return this.http.post(this.url+"api/v1/getBothColumnByTable",data);
   }
   getColumnByTableIns(data:any){
    return this.http.post(this.url+"api/v1/getColumnByTableIns",data);
   }
   insertApplication(data:any){
    return this.http.post(this.url+"api/v1/createUpdateApp",data);
   }
   createReportLog(data:any){
    return this.http.post(this.url+"api/v1/createReportLog",data);
   }
   getAllReportLogByAppId(data:any){
    return this.http.post(this.url+"api/v1/getAllReportLogByAppId",data);
  }
  getInsuffApplications(data:any){
    return this.http.post(this.url+"api/v1/getInsuffApplications",data);
  }
  getInsuffClearApplications(data:any){
    return this.http.post(this.url+"api/v1/getInsuffClearApplications",data);
  }
   getAllApplication(data:any){
    return this.http.post(this.url+"api/v1/application",data);
  }
  getAllApplicationInit(data:any){
    return this.http.post(this.url+"api/v1/applicationInit",data);
  }
  getAllApplicationByEmp(data:any){
    return this.http.post(this.url+"api/v1/getAllApplicationByEmp",data);
  }
  getAllUsersV2(data:any){
    return this.http.post(this.url+"api/v1/getAllUsersV2",data);
  }
  getAllSpocByClientWithTaskLog(data:any){
    return this.http.post(this.url+"api/v1/getAllSpocByClientWithTaskLog",data);
  }
  getAllTaskLog(data:any){
    return this.http.post(this.url+"api/v1/getAllTaskLog",data);
  }
  getAppById(id){
    return this.http.get(this.url+"api/v1/application/"+id);
   }

   getAllCountData(data:any){
    return this.http.post(this.url+"api/v1/getAllCountData",data);
  }
  getAllCountDataInit(id=""){
    return this.http.get(this.url+"api/v1/getAllCountDataInit"+id);
  }
  getAllCountDataV2(id=""){
    return this.http.get(this.url+"api/v1/getAllCountDataV2"+id);
  }
  getClientWithCount(id=""){
    return this.http.get(this.url+"api/v1/getClientWithCount"+id);
  }
  getMonthWiseData(data:any){
    return this.http.post(this.url+"api/v1/getMonthWiseData",data);
  }
  getApplicationCountByStatus(data:any){
    return this.http.post(this.url+"api/v1/getApplicationCountByStatus",data);
  }
  getApplicationCountByStatusInit(data:any){
    return this.http.post(this.url+"api/v1/getApplicationCountByStatusInit",data);
  }
  getApplicationCountByStatusByEmp(id=""){
    return this.http.get(this.url+"api/v1/getApplicationCountByStatusByEmp"+id);
  }

  inviteEmail(data:any){
    return this.http.post(this.url+"api/v1/inviteEmail",data);
   }
   getApplicationByStatus(data:any){
    return this.http.post(this.url+"api/v1/getApplicationByStatus",data);
   }
   getApplicationByFilter(data:any){
    return this.http.post(this.url+"api/v1/getApplicationByFilter",data);
   }
   getApplicationBySearch(data:any){
    return this.http.post(this.url+"api/v1/getApplicationBySearch",data);
   }
   getCheckBySearch(data:any){
    return this.http.post(this.url+"api/v1/getCheckBySearch",data);
   }
   getCheckByFilter(data:any){
    return this.http.post(this.url+"api/v1/getCheckByFilter",data);
   }
   getCountBySearch(data:any){
    return this.http.post(this.url+"api/v1/getCountBySearch",data);
   }
   getCountByFilter(data:any){
    return this.http.post(this.url+"api/v1/getCountByFilter",data);
   }

   getClientCount(data:any){
    return this.http.post(this.url+"api/v1/getClientCount",data);
   }

   uploadApplicationExcel(data:any){
    return this.http.post(this.url+"api/v1/uploadApplicationExcel",data);
   }
   updateApplicationExcel(data:any){
    return this.http.post(this.url+"api/v1/updateApplicationExcel",data);
   }
   uploadCheckExcel(data:any){
    return this.http.post(this.url+"api/v1/uploadCheckExcel",data);
   }

   getApplications(data:any){
    return this.http.post(this.url+"api/v1/getApplications",data);
   }
   getApplicationsInit(id){
    return this.http.get(this.url+"api/v1/getApplicationsInit",id);
   }
   getApplicationsEmp(id){
    return this.http.get(this.url+"api/v1/getApplicationsEmp",id);
   }

   getApplicationById(id){
    return this.http.get(this.url+"api/v1/application/"+id);
   }
   getApplicationByIdInvite(id){
    return this.http.get(this.url+"api/v1/applicationInvite/"+id);
   }

   genrateReport(data:any){
    return this.http.post(this.url+"api/v1/generatePdf",data);
   }

   getClientMisCount(data:any){
    return this.http.post(this.url+"api/v1/getClientMisCount/",data);
   }
   getClientMisData(data:any){
    return this.http.post(this.url+"api/v1/getClientMisData/",data);
   }
   getMisClientDataAll(data:any){
    return this.http.post(this.url+"api/v1/getMisClientDataAll/",data);
   }
   getClientMisChecksData(data:any){
    return this.http.post(this.url+"api/v1/getClientMisChecksData/",data);
   }

   updateAppStatus(data:any){
    return this.http.post(this.url+"api/v1/updateAppStatus/",data);
   }


}
