import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WebapiService } from '../webapi.service';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-application',
  templateUrl: './new-application.component.html',
  styleUrls: ['./new-application.component.scss']
})
export class NewApplicationComponent implements OnInit {
  @ViewChild('appfile') fileInput!: ElementRef;
  formData: any = {};
  startDate: any;
  endDate: any;
  clientList: any;
  clientChecks: any=[];
  checksArr: any = [];
  processList: any;
  loginId: any;
  appData: any;
  appId: any;
  appIdCount: any;
  checksCount: any;
  checkCode:any
  copyAppId: any;
  appPreview: any;
  loginType: any;
  constructor(private WebApiService: WebapiService, private router: Router, private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {

    this.getClient();

  this.appId = this.activatedRoute.snapshot.paramMap.get('id');
  this.copyAppId = this.activatedRoute.snapshot.queryParamMap.get('copy');

    if(this.appId) {
      this.getApplicationdetail(this.appId);
    }
    if(this.copyAppId) {
      this.getApplicationdetail(this.copyAppId);
    }

    this.loginId = sessionStorage.getItem('loginId');
    this.loginType = sessionStorage.getItem('type');

    if(this.loginType == 3){
      this.getProcessByClientV2(this.loginId);
      this.formData.clientId = this.loginId;
    }
  }

   getApplicationdetail(id:any){
    this.WebApiService.getApplicationById(id).subscribe((res:any)=>{
      // console.log(res);
      if(this.appId){
        this.formData = res;
      }
      if(this.copyAppId){
        this.formData = res;
        this.getProcessByClient(res.clientId);
        delete this.formData._id;
        delete this.formData.checksData
        delete this.formData.process;
        delete this.formData.appProcess;
        delete this.formData.applicationId;
        delete this.formData.created;
        delete this.formData.generatePdf;
      }
     // if(res.invite && res.invite == "false"){
        //   this.formData.invite = false;
        // }
        // else{
        //   this.formData.invite = true;
        // }
      this.getProcessByClientV2(res.clientId);

    });


  }

  getClient() {
    this.WebApiService.getAllClient().subscribe((res: any) => {
      // console.log(res);
      this.clientList = res.user;
    });
  }
  getProcessByClientV2(id:any){
    this.getAppCount(id);
    setTimeout(()=>{
      let arr = this.clientList.filter(e=> e._id == id);
      // console.log(arr,'fgh');
      this.formData.clientName = arr[0].name
      this.processList = arr[0].processList;
      this.checkCode =arr[0].checkCodePrefix;
      if(this.appIdCount < 1){
        this.formData.applicationId = arr[0].appCodePrefix+'1';
      }
      else{
        this.formData.applicationId = arr[0].appCodePrefix +`${this.appIdCount + 1}`
      }
      // console.log(id,arr,this.checkCode,this.appIdCount,'---');
    },1000)
    
  }
  getAppCount(id:any){
    this.WebApiService.getAppCount(id).subscribe((res:any)=>{
       this.appIdCount = res.appCount;
       this.checksCount = res.checksCount
    });
  }
  getProcessByClient(a){
    if(this.copyAppId){
      this.getAppCount(a);
      setTimeout(() => {
        let arr = this.clientList.filter(e=> e._id == a);
        console.log(arr);
        
        this.formData.clientName = arr[0].name
        this.checkCode =arr[0].checkCodePrefix;
        this.processList = arr[0].processList;
        if(this.appIdCount < 1){
          this.formData.applicationId = arr[0].appCodePrefix+'1';
        }
        else{
          this.formData.applicationId = arr[0].appCodePrefix +`${this.appIdCount + 1}`
        }
      }, 1000);
    }
    else{``
      this.getAppCount(a.target.value);
      setTimeout(() => {
        let arr = this.clientList.filter(e=> e._id == a.target.value);
        this.formData.clientName = arr[0].name
        this.processList = arr[0].processList;
        this.checkCode =arr[0].checkCodePrefix;
        if(this.appIdCount < 1){
          this.formData.applicationId = arr[0].appCodePrefix+'1';
        }
        else{
          this.formData.applicationId = arr[0].appCodePrefix +`${this.appIdCount + 1}`
        }
      }, 1000);
    }

  }


  getCLientProcessData(e: any) {
    this.checksArr = [];
    let id = e.target.value;
    let arr = this.processList.filter(c=> c.process == e.target.value);
    let final = arr[0].checkList.filter(c=> c.selected == true);
    // console.log(final);
    this.formData.checksData = final;
  }

  negativeAlert(e:any){
if(e.target.value < 0){
  alert("Cannot be negative");
  e.target.value ='';
}
  }
  getCLientProcessDataV2(e:any) {
    this.checksArr = [];
    let id = e;
    this.WebApiService.getProcessChecks(id).subscribe((res: any) => {
      // console.log(res);
      this.clientChecks = JSON.parse(res[0].processChecks);
      // console.log(this.clientChecks);
      this.clientChecks.forEach(e => {
        this.WebApiService.getColumnByTableIns({"tableName":e}).subscribe((res: any) => {
          // console.log(res);
          if(res.length > 0) {
            let val = {
              "name":e,
              "arr":res
            }
            this.checksArr.push(val);
          }
        });

      });


    });


  }
  insertApplication(data: any) {
    if(this.appId){
      data._id = this.appId;
    }
    if(!data.invite){
      data.invite = false;
    }
    if(data.invite == true){
      // this.inviteCandidate(data);
    }
    if(!this.appId || this.copyAppId){
      console.log('jj');
      
      data.isActive = true;
      data.status = "UNAPPROVED";
      for (let i = 0; i < data.checksData.length; i++) {
        // console.log('check testng-------------------');

        data.checksData[i].checkId = this.checkCode + `${this.checksCount + (i == 0 ? 1 : i+1)}`;
        data.checksData[i].status = 'NEW';
      }
    }
    else{
      data.isActive = true;
      data.status = "UNAPPROVED";
    }
 
    data.performBy = this.loginId;
    this.WebApiService.insertApplication(data).subscribe((res: any) => {
      console.log(res);
      if(res.status == "ok"){
        alert(res.msg);
        this.router.navigate(['/application']);
      }
      else{
        alert(res.msg);
        this.router.navigate(['/application']);
      }

    });

  }

  // inviteCandidate(id:any,candName:any,clientName:any,email:any){
  //   let val = {
  //    appId:id,
  //    candName:candName,
  //    clientName:clientName,
  //    url:this.inviteUrl,
  //    candidateEmail:email
  //   }
  //   this.WebApiService.inviteEmail(val).subscribe((res:any)=>{
  //    console.log(res);
  //    if(res.status == "ok"){
  //      alert(res.msg);
  //    }
  //    else{
  //      alert("something went wrong");
  //    }
  //  });
  //  }

  getStartDate(e: any) {
    this.startDate = new Date(e.target.value);
    // console.log(this.startDate.getTime());

    if(this.startDate && this.endDate){
      let timeDiff = this.startDate.getTime() - this.endDate.getTime();
      let daysDiff = timeDiff / (1000 * 3600 * 24);
        this.formData.tatDays = Math.abs(daysDiff);
    }
    //  console.log(this.startDate);

  }
  getEndData(e: any) {
    this.endDate = new Date(e.target.value);
    if(this.endDate && this.startDate){
      let timeDiff = this.endDate.getTime() - this.startDate.getTime();

      if(timeDiff < 0 ){
        alert("end date should be greater than start date");
        this.formData.tatEnd = "";
        this.formData.tatDays = "";
      }
      else{
        let daysDiff = timeDiff / (1000 * 3600 * 24);
        this.formData.tatDays = Math.abs(daysDiff);
      }

    }

  }

  getFile(e:any){
    // this.formData.file = e.target.files[0];
    const formData = new FormData();
    formData.append('image',e.target.files[0]);
    formData.append('type','return');
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.appPreview = e.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    this.WebApiService.uploadImage(formData).subscribe((res: any) => {
       if(res.status == "ok"){
         alert('upload success');
         this.formData.appDoc = res.imageName
        }
        else{
         alert("something went wrong")
        }
     });
  }


  getChecksFile(e:any,index:any){
  const formData = new FormData();
  formData.append('image',e.target.files[0]);
  formData.append('type','return');
  if (e.target.files[0]) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.formData.checksData[index].chkPreview = e.target.result;
    };
    reader.readAsDataURL(e.target.files[0]);
  }
  this.WebApiService.uploadImage(formData).subscribe((res: any) => {
     if(res.status == "ok"){
       alert('upload success');
       e.target.value = '';
       this.formData.checksData[index].checkDoc = [res.imageName];
      }
      else{
       alert("something went wrong")
      }
   });
  }

  removeAppPreview(){
    this.appPreview = '';
    this.formData.appDoc = '';
    this.fileInput.nativeElement.value = '';
  }

  removechkPreview(i:any){
    this.formData.checksData[i].chkPreview = ''
    this.formData.checksData[i].checkDoc = [];
  }

}
